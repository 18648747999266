import {ListenerEffectAPI, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, AppState, store} from 'store/customer/storeSetup';
import {
    coordinatesSet,
    cornerSet,
    cornersSet,
    edgeProfileSet,
    joinSet,
    joinsSet,
    pathsSet,
    profiledSet,
    sideSet,
    dimensionErrorsSet,
} from 'components/customer/BTM/store/btmSlice';
import {getCoordinates} from 'components/customer/BTM/helper/getCoordinates';
import {cutoffs} from 'components/customer/BTM/helper/corner';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {getPathsByCoordinates} from 'components/customer/BTM/helper/paths';
import {getJoins} from 'components/customer/BTM/helper/joins';
import {cloneDeep} from 'lodash';
import {Side} from 'components/customer/BTM/entity/Side';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {Join} from 'components/customer/BTM/entity/Join';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {joinRestrictions} from 'components/customer/BTM/helper/joinRestrictions';
import {cornerRestrictions} from 'components/customer/BTM/helper/cornerRestrictions';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {getDimensionError} from 'components/customer/BTM/store/middleware/dimensionUpdateMiddleware';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {
    setClippedCornersFromEndProfile,
    setCorners,
} from 'components/customer/BTM/helper/setCorners';
import {Path} from 'components/customer/BTM/entity/Path';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {
    addRemoveCutoffs,
    resetEndProfileByOppositeSide,
} from 'components/customer/BTM/helper/curvedCorner';

/**
 * The function that is used by most of the middleware to update some data of bench
 * in relation with others
 *
 * @param {PayloadAction<Corner | number, string, {index: number}>} action Action that triggred this middleware
 * @param {ListenerEffectAPI<AppState, AppDispatch>} listenerApi
 * @param {boolean} setCorner
 * @return {void}
 */
const updateShape = async (
    action: PayloadAction<
        Corner | Corner[] | number,
        string | null,
        {index?: number; profile?: BenchtopEdgeProfile} | null
    >,
    listenerApi: ListenerEffectAPI<AppState, AppDispatch>,
    setCorner = false
) => {
    const state = listenerApi.getState();

    let initialCorners = cloneDeep(state.btm.corners);
    const dimension = state.btm.dimension;
    const shape = state.btm.type;
    const joins = state.btm.joins;
    const currentPaths = state.btm.paths;
    const edgeProfile = state.btm.edgeProfile;

    if (action.type == sideSet.type) {
        if (shape.type == Shape.USHAPE) {
            if ([Side.E].includes(action?.meta?.index)) {
                return;
            }
        } else if (shape.type == Shape.ANG) {
            if ([Side.C, Side.D].includes(action?.meta?.index)) {
                return;
            }
        }
    }

    if (action.type == sideSet.type && setCorner) {
        const depth = (action.payload as number) / 2 - 1;

        const conditions = {
            [Shape.SQR]: {sides: [Side.B], names: [] as string[][]},
            [Shape.ANG]: {
                sides: [Side.B, Side.E],
                names: [
                    ['AB', 'BC'],
                    ['DE', 'EF'],
                ],
            },
            [Shape.USHAPE]: {
                sides: [Side.C, Side.G],
                names: [
                    ['BC', 'CD'],
                    ['FG', 'GH'],
                ],
            },
        };

        const currentCondition = conditions[shape.type];

        const side = action.meta.index;
        if (currentCondition && currentCondition.sides.includes(side)) {
            const sideIndex = currentCondition.sides.indexOf(side);
            const names = currentCondition.names[Number(sideIndex)];

            initialCorners = initialCorners.map((corner) => {
                if (
                    ((names && names.includes(corner.name)) ||
                        typeof names == 'undefined') &&
                    corner.isArc
                ) {
                    const newDepths =
                        side == Side.B
                            ? [corner.depths[0], depth]
                            : [depth, corner.depths[1]];
                    return {...corner, depths: newDepths};
                }
                return corner;
            });
        }
    }

    const {coordinates} = getCoordinates(shape.type, dimension);
    const {points, corners} = cutoffs(initialCorners, coordinates);

    const paths = getPathsByCoordinates(
        points,
        currentPaths,
        action?.meta?.profile
    );

    if (shape.type != Shape.SQR) {
        let newJoins = getJoins(joins, corners, dimension, shape.type);
        newJoins = joinRestrictions(newJoins, paths, edgeProfile);

        store.dispatch(joinsSet(newJoins));
    }

    store.dispatch(coordinatesSet(points));
    store.dispatch(pathsSet(paths));

    if (setCorner) {
        const updatedCorners = await cornerRestrictions(corners, paths);
        store.dispatch(cornersSet(updatedCorners));
    }
};

const checkIfLengthIsValid = (length: number, corners: Corner[], index = 0) => {
    return !corners.some((corner) => {
        const depth = corner.depths[Number(index)];

        if (depth * 2 > length) {
            return true;
        }

        return false;
    });
};

/**
 * This function takes in dimensions, shape of bench, joins information,
 * and selected material data to check if the entered dimensions are
 * valid or not.
 *
 * @param {number[]} dimensions Dimensions of the bench
 * @param {Shape} shape shape of the bench e.g. Rectangle, U or L Shape
 * @param {Join[]} joins All the joins available for the bench look at btmSlice
 * @param {BenchtopMaterial} material The selected material
 * @param {Corner[]} corners The corners of the bench
 * @param {boolean} [filterEmpty] Whether or not to filter empty error messages
 * @param {string[]} messages The list of error messages
 * @return {string[]} The list of error messages
 */
export const getDimensionErrors = (
    dimensions: number[],
    shape: Shape,
    joins: Join[],
    material: BenchtopMaterial,
    corners: Corner[],
    filterEmpty = false,
    messages: string[] = []
) => {
    let dimensionMessages = cloneDeep(messages);
    const dimensionCopy = cloneDeep(dimensions);

    if (dimensionCopy.length == 8) {
        dimensionCopy.push(dimensionCopy[Side.H] - dimensionCopy[Side.F]);
    }

    dimensionCopy.forEach((length, side) => {
        const error = getDimensionError(
            side,
            shape,
            joins,
            material,
            dimensionCopy,
            length
        );

        if (error) {
            dimensionMessages[Number(side)] = error;
            return;
        }

        const arcCorners = corners.filter((corner) => corner?.isArc);

        if (arcCorners.length > 0) {
            let isValid = true;
            const conditions = {
                [Shape.SQR]: {sides: [Side.B], names: ['']},
                [Shape.ANG]: {sides: [Side.B, Side.E], names: ['AB', 'DE']},
                [Shape.USHAPE]: {sides: [Side.C, Side.G], names: ['BC', 'FG']},
            };

            // Safe: currentCondition is checked before being used.
            // eslint-disable-next-line security/detect-object-injection
            const currentCondition = conditions[shape];

            if (currentCondition && currentCondition.sides.includes(side)) {
                const name =
                    currentCondition.names[
                        currentCondition.sides.indexOf(side)
                    ];
                const corners = name
                    ? arcCorners.filter((corner) => corner.name == name)
                    : arcCorners;
                isValid = checkIfLengthIsValid(
                    length,
                    corners,
                    side == Side.B ? 0 : 1
                );
            }

            if (!isValid) {
                dimensionMessages[Number(side)] =
                    "Arc radius applied on one of the edges can't be more than specified depth";
                return;
            }
        }

        if (
            typeof dimensionMessages[Number(side)] == 'undefined' ||
            dimensionMessages[Number(side)].length == 0
        ) {
            dimensionMessages[Number(side)] = '';
        }
    });

    if (filterEmpty) {
        dimensionMessages = dimensionMessages.filter(
            (message) => message.length > 0
        );
    }

    return dimensionMessages;
};

export const dimensionUpdateEffect = () => ({
    actionCreator: sideSet,
    effect: (
        action: PayloadAction<number, string, {index: Side}>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        void updateShape(action, listenerApi, true);
    },
});

export const profileSideEffect = () => ({
    actionCreator: profiledSet,
    effect: async (
        action: PayloadAction<
            Edge,
            string,
            {
                index: number;
                profile: BenchtopEdgeProfile;
                side: Side;
                profiles: BenchtopEdgeProfile[];
            }
        >,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        await updateShape(action, listenerApi, true);

        const profile = action?.meta?.profile;
        const state = listenerApi.getState();
        const paths = state.btm.paths;

        if (
            profile &&
            profile.is_end_roll_available &&
            profile.restrict_adjacent
        ) {
            const shape = state.btm.type;

            setCorners(action?.meta?.side, action?.meta?.index, shape.type);
        }
        // check if selected profile is end only profile and also has end option selected then there needs to be clips or arc added to preview
        const shape = state.btm.type;
        const dimension = state.btm.dimension;

        if (profile && profile.is_end_only && profile.end_id) {
            if (profile.end_option.is_arc) {
                const dimension = state.btm.dimension;
                let firstCornerPoints;
                let secondCornerPoints;
                let name;
                let direction;
                let depth;
                let index;
                let hideCornerInPreview = [false, false];

                if (shape.type == Shape.SQR) {
                    if (action?.meta?.side == Side.B) {
                        firstCornerPoints = {x: dimension[0], y: 0};
                        secondCornerPoints = {x: dimension[0], y: dimension[1]};
                        name = ['AB', 'BC'];
                        index = [1, 2];
                        direction = [Direction.LEFT_DOWN, Direction.LEFT_UP];
                        // 1mm deduction is to prevent preview from touching 2 corners at 1 point
                        depth = [
                            profile.end_option.default_radius_start,
                            dimension[1] / 2 - 1,
                        ];
                        hideCornerInPreview = [false, true];
                    }

                    if (action?.meta?.side == Side.D) {
                        firstCornerPoints = {x: 0, y: 0};
                        secondCornerPoints = {x: 0, y: dimension[1]};
                        name = ['DA', 'CD'];
                        index = [0, 3];

                        direction = [Direction.RIGHT_DOWN, Direction.RIGHT_UP];
                        depth = [
                            profile.end_option.default_radius_start,
                            dimension[1] / 2 - 1,
                        ];
                        hideCornerInPreview = [true, false];
                    }
                } else if (shape.type == Shape.ANG) {
                    if (action?.meta?.side == Side.B) {
                        firstCornerPoints = {x: dimension[0], y: 0};
                        secondCornerPoints = {x: dimension[0], y: dimension[1]};
                        name = ['AB', 'BC'];
                        index = [1, 2];
                        direction = [Direction.LEFT_DOWN, Direction.LEFT_UP];
                        depth = [
                            profile.end_option.default_radius_start,
                            dimension[1] / 2 - 1,
                        ];
                        hideCornerInPreview = [true, false];
                    }

                    if (action?.meta?.side == Side.E) {
                        firstCornerPoints = {x: dimension[4], y: dimension[5]};
                        secondCornerPoints = {x: 0, y: dimension[5]};
                        name = ['DE', 'EF'];
                        index = [4, 5];

                        direction = [Direction.LEFT_UP, Direction.RIGHT_UP];
                        depth = [
                            dimension[4] / 2 - 1,
                            profile.end_option.default_radius_start,
                        ];
                        hideCornerInPreview = [false, true];
                    }
                } else {
                    // USHAPE
                    if (action?.meta?.side == Side.C) {
                        firstCornerPoints = {x: dimension[0], y: dimension[1]};
                        secondCornerPoints = {
                            x: dimension[0] - dimension[2],
                            y: dimension[1],
                        };
                        name = ['BC', 'CD'];
                        index = [2, 3];
                        direction = [Direction.LEFT_UP, Direction.RIGHT_UP];
                        depth = [
                            dimension[2] / 2 - 1,
                            profile.end_option.default_radius_start,
                        ];
                        hideCornerInPreview = [false, true];
                    }

                    if (action?.meta?.side == Side.G) {
                        firstCornerPoints = {
                            x: dimension[0] - (dimension[2] + dimension[4]),
                            y: dimension[7],
                        };
                        secondCornerPoints = {x: 0, y: dimension[7]};
                        name = ['FG', 'GH'];
                        index = [6, 7];

                        direction = [Direction.LEFT_UP, Direction.RIGHT_UP];
                        depth = [
                            dimension[6] / 2 - 1,
                            profile.end_option.default_radius_start,
                        ];
                        hideCornerInPreview = [false, true];
                    }
                }

                setClippedCornersFromEndProfile(
                    index[0],
                    firstCornerPoints,
                    depth,
                    direction[0],
                    name[0],
                    CornerType.Clip,
                    true,
                    true,
                    index[1],
                    hideCornerInPreview[0]
                );

                setClippedCornersFromEndProfile(
                    index[1],
                    secondCornerPoints,
                    depth,
                    direction[1],
                    name[1],
                    CornerType.Clip,
                    true,
                    true,
                    index[0],
                    hideCornerInPreview[1]
                );
            } else {
                // add 2 corner clips
                if (shape.type == Shape.SQR) {
                    const pathA = paths.find((path) => path.side == Side.A);
                    const pathC = paths.find((path) => path.side == Side.C);

                    if (action?.meta?.side == Side.B) {
                        setClippedCornersFromEndProfile(
                            1,
                            {x: dimension[0], y: 0},
                            [
                                profile.end_option.default_radius_end,
                                profile.end_option.default_radius_start,
                            ],
                            Direction.LEFT_DOWN,
                            'AB',
                            CornerType.Clip,
                            pathA.edged == Edge.PROFILED
                        );

                        setClippedCornersFromEndProfile(
                            2,
                            {x: dimension[0], y: dimension[1]},
                            [
                                profile.end_option.default_radius_end,
                                profile.end_option.default_radius_start,
                            ],
                            Direction.LEFT_UP,
                            'BC',
                            CornerType.Clip,
                            pathC.edged == Edge.PROFILED
                        );
                    }

                    if (action?.meta?.side == Side.D) {
                        setClippedCornersFromEndProfile(
                            0,
                            {x: 0, y: 0},
                            [
                                profile.end_option.default_radius_end,
                                profile.end_option.default_radius_start,
                            ],
                            Direction.RIGHT_DOWN,
                            'DA',
                            CornerType.Clip,
                            pathA.edged == Edge.PROFILED
                        );

                        setClippedCornersFromEndProfile(
                            3,
                            {x: 0, y: dimension[1]},
                            [
                                profile.end_option.default_radius_end,
                                profile.end_option.default_radius_start,
                            ],
                            Direction.RIGHT_UP,
                            'CD',
                            CornerType.Clip,
                            pathC.edged == Edge.PROFILED
                        );
                    }
                } else if (shape.type == Shape.ANG) {
                    if (action?.meta?.side == Side.B) {
                        const pathA = paths.find((path) => path.side == Side.A);
                        const pathC = paths.find((path) => path.side == Side.C);

                        setClippedCornersFromEndProfile(
                            1,
                            {x: dimension[0], y: 0},
                            [
                                profile.end_option.default_radius_end,
                                profile.end_option.default_radius_start,
                            ],
                            Direction.LEFT_DOWN,
                            'AB',
                            CornerType.Clip,
                            pathA.edged == Edge.PROFILED
                        );

                        setClippedCornersFromEndProfile(
                            2,
                            {x: dimension[0], y: dimension[1]},
                            [
                                profile.end_option.default_radius_end,
                                profile.end_option.default_radius_start,
                            ],
                            Direction.LEFT_UP,
                            'BC',
                            CornerType.Clip,
                            pathC.edged == Edge.PROFILED
                        );
                    }

                    if (action?.meta?.side == Side.E) {
                        const pathD = paths.find((path) => path.side == Side.D);
                        const pathF = paths.find((path) => path.side == Side.F);

                        setClippedCornersFromEndProfile(
                            4,
                            {x: dimension[4], y: dimension[5]},
                            [
                                profile.end_option.default_radius_start,
                                profile.end_option.default_radius_end,
                            ],
                            Direction.LEFT_UP,
                            'DE',
                            CornerType.Clip,
                            pathD.edged == Edge.PROFILED
                        );

                        setClippedCornersFromEndProfile(
                            5,
                            {x: 0, y: dimension[5]},
                            [
                                profile.end_option.default_radius_start,
                                profile.end_option.default_radius_end,
                            ],
                            Direction.RIGHT_UP,
                            'EF',
                            CornerType.Clip,
                            pathF.edged == Edge.PROFILED
                        );
                    }
                } else {
                    // USHAPE
                    if (action?.meta?.side == Side.C) {
                        const pathB = paths.find((path) => path.side == Side.B);
                        const pathD = paths.find((path) => path.side == Side.D);

                        setClippedCornersFromEndProfile(
                            2,
                            {x: dimension[0], y: dimension[1]},
                            [
                                profile.end_option.default_radius_start,
                                profile.end_option.default_radius_end,
                            ],
                            Direction.LEFT_UP,
                            'BC',
                            CornerType.Clip,
                            pathB.edged == Edge.PROFILED
                        );

                        setClippedCornersFromEndProfile(
                            3,
                            {
                                x: dimension[0] - dimension[2],
                                y: dimension[1],
                            },
                            [
                                profile.end_option.default_radius_start,
                                profile.end_option.default_radius_end,
                            ],
                            Direction.RIGHT_UP,
                            'CD',
                            CornerType.Clip,
                            pathD.edged == Edge.PROFILED
                        );
                    }

                    if (action?.meta?.side == Side.G) {
                        const pathF = paths.find((path) => path.side == Side.F);
                        const pathH = paths.find((path) => path.side == Side.H);

                        setClippedCornersFromEndProfile(
                            6,
                            {
                                x: dimension[0] - (dimension[2] + dimension[4]),
                                y: dimension[7],
                            },
                            [
                                profile.end_option.default_radius_start,
                                profile.end_option.default_radius_end,
                            ],
                            Direction.LEFT_UP,
                            'FG',
                            CornerType.Clip,
                            pathF.edged == Edge.PROFILED
                        );

                        setClippedCornersFromEndProfile(
                            7,
                            {x: 0, y: dimension[7]},
                            [
                                profile.end_option.default_radius_start,
                                profile.end_option.default_radius_end,
                            ],
                            Direction.RIGHT_UP,
                            'GH',
                            CornerType.Clip,
                            pathH.edged == Edge.PROFILED
                        );
                    }
                }
            }
        } else {
            // check if there are corners added from the end profile and remove them if any non-end profile is selected
            const corners = state.btm.corners;
            const profiles = action?.meta?.profiles;

            if (shape.type == Shape.SQR) {
                if (action?.meta?.side == Side.A) {
                    const path = paths.find((path) => path.side == Side.A);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.C, [
                            Side.B,
                            Side.D,
                        ]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.A,
                        [Side.B, Side.D],
                        ['AB', 'DA'],
                        [1, 0],
                        [
                            {x: dimension[0], y: 0},
                            {x: 0, y: 0},
                        ],
                        [Direction.LEFT_DOWN, Direction.RIGHT_DOWN]
                    );
                }

                if (action?.meta?.side == Side.C) {
                    const path = paths.find((path) => path.side == Side.C);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.A, [
                            Side.B,
                            Side.D,
                        ]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.C,
                        [Side.B, Side.D],
                        ['BC', 'CD'],
                        [2, 3],
                        [
                            {x: dimension[0], y: dimension[1]},
                            {x: 0, y: dimension[1]},
                        ],
                        [Direction.LEFT_UP, Direction.RIGHT_UP]
                    );
                }
            } else if (shape.type == Shape.ANG) {
                if (action?.meta?.side == Side.A) {
                    const path = paths.find((path) => path.side == Side.A);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.C, [Side.B]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.A,
                        [Side.B],
                        ['AB'],
                        [1],
                        [{x: dimension[0], y: 0}],
                        [Direction.LEFT_DOWN]
                    );
                }

                if (action?.meta?.side == Side.C) {
                    const path = paths.find((path) => path.side == Side.C);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.A, [Side.B]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.C,
                        [Side.B],
                        ['BC'],
                        [2],
                        [{x: dimension[0], y: dimension[1]}],
                        [Direction.LEFT_UP]
                    );
                }

                if (action?.meta?.side == Side.D) {
                    const path = paths.find((path) => path.side == Side.D);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.F, [Side.E]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.D,
                        [Side.E],
                        ['DE'],
                        [4],
                        [{x: dimension[4], y: dimension[5]}],
                        [Direction.LEFT_UP],
                        false
                    );
                }

                if (action?.meta?.side == Side.F) {
                    const path = paths.find((path) => path.side == Side.F);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.D, [Side.E]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.F,
                        [Side.E],
                        ['EF'],
                        [5],
                        [{x: 0, y: dimension[5]}],
                        [Direction.RIGHT_UP],
                        false
                    );
                }
            } else if (shape.type == Shape.USHAPE) {
                if (action?.meta?.side == Side.B) {
                    const path = paths.find((path) => path.side == Side.B);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.D, [Side.C]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.B,
                        [Side.C],
                        ['BC'],
                        [2],
                        [{x: dimension[0], y: dimension[1]}],
                        [Direction.LEFT_UP],
                        false
                    );
                }

                if (action?.meta?.side == Side.D) {
                    const path = paths.find((path) => path.side == Side.D);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.B, [Side.C]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.D,
                        [Side.C],
                        ['CD'],
                        [3],
                        [
                            {
                                x: dimension[0] - dimension[2],
                                y: dimension[1],
                            },
                        ],
                        [Direction.RIGHT_UP],
                        false
                    );
                }

                if (action?.meta?.side == Side.F) {
                    const path = paths.find((path) => path.side == Side.F);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.H, [Side.G]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.F,
                        [Side.G],
                        ['FG'],
                        [6],
                        [
                            {
                                x: dimension[0] - (dimension[2] + dimension[4]),
                                y: dimension[7],
                            },
                        ],
                        [Direction.LEFT_UP],
                        false
                    );
                }

                if (action?.meta?.side == Side.H) {
                    const path = paths.find((path) => path.side == Side.H);

                    if (path.edged != Edge.PROFILED) {
                        resetEndProfileByOppositeSide(paths, Side.F, [Side.G]);
                    }

                    addRemoveCutoffs(
                        paths,
                        profiles,
                        corners,
                        Side.H,
                        [Side.G],
                        ['GH'],
                        [7],
                        [{x: 0, y: dimension[7]}],
                        [Direction.RIGHT_UP],
                        false
                    );
                }
            }

            if (corners.length) {
                let validCornerNames: string[] = [];
                if (shape.type == Shape.SQR) {
                    const isSideB = action?.meta?.side === Side.B;
                    const isSideD = action?.meta?.side === Side.D;
                    const validCornerNamesB = ['AB', 'BC'];
                    const validCornerNamesD = ['DA', 'CD'];

                    if (isSideB || isSideD) {
                        validCornerNames = isSideB
                            ? validCornerNamesB
                            : validCornerNamesD;
                    }
                } else if (shape.type == Shape.ANG) {
                    const isSideB = action?.meta?.side === Side.B;
                    const isSideE = action?.meta?.side === Side.E;
                    const validCornerNamesB = ['AB', 'BC'];
                    const validCornerNamesE = ['DE', 'EF'];

                    if (isSideB || isSideE) {
                        validCornerNames = isSideB
                            ? validCornerNamesB
                            : validCornerNamesE;
                    }
                } else {
                    // USHAPE
                    const isSideC = action?.meta?.side === Side.C;
                    const isSideG = action?.meta?.side === Side.G;
                    const validCornerNamesC = ['BC', 'CD'];
                    const validCornerNamesG = ['FG', 'GH'];

                    if (isSideC || isSideG) {
                        validCornerNames = isSideC
                            ? validCornerNamesC
                            : validCornerNamesG;
                    }
                }

                if (validCornerNames.length > 0) {
                    const newCorners = corners.map((corner) => {
                        if (
                            corner?.addedThroughEndProfile &&
                            validCornerNames.indexOf(corner.name) > -1
                        ) {
                            return {
                                ...corner,
                                isArc: undefined,
                                addedThroughEndProfile: undefined,
                                type: CornerType.None,
                                cutoff: false,
                            };
                        }
                        return corner;
                    });

                    const paths = state.btm.paths;
                    const updatedCorners = await cornerRestrictions(
                        newCorners,
                        paths
                    );

                    store.dispatch(cornersSet(updatedCorners));
                }
            }
        }
    },
});

// don't recalculate joins when updating clips
export const cornerUpdateEffect = () => ({
    actionCreator: cornerSet,
    effect: (
        action: PayloadAction<Corner, string, {index: number}>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        void updateShape(action, listenerApi);
    },
});
// don't recalculate joins when updating clips
export const cornersUpdateEffect = () => ({
    actionCreator: cornersSet,
    effect: (
        action: PayloadAction<Corner[], null, null>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        void updateShape(action, listenerApi);
    },
});

export const profileSetEffect = () => ({
    actionCreator: edgeProfileSet,
    effect: async (
        action: PayloadAction<BenchtopEdgeProfile>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        const state = listenerApi.getState();
        const corners = state.btm.corners;
        const paths = state.btm.paths;
        const dimension = state.btm.dimension;
        const shape = state.btm.type;
        const joins = state.btm.joins;
        const edgeProfile = state.btm.edgeProfile;

        if (shape.type != Shape.SQR) {
            let newJoins = getJoins(joins, corners, dimension, shape.type);
            newJoins = joinRestrictions(newJoins, paths, edgeProfile);

            store.dispatch(joinsSet(newJoins));
        }

        const updatedCorners = await cornerRestrictions(corners, paths);
        listenerApi.dispatch(cornersSet(updatedCorners));
    },
});

export const joinSetEffectValidateLengths = () => ({
    actionCreator: joinSet,
    effect: (
        action: PayloadAction<boolean>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        const state = listenerApi.getState();
        const dimensions = state.btm.dimension;
        const benchType = state.btm.type;
        const joins = state.btm.joins;
        const material = state.btm.material;
        const corners = state.btm.corners;
        const messages = state.btm.dimensionError;

        const dimensionMessages = getDimensionErrors(
            dimensions,
            benchType.type,
            joins,
            material,
            corners,
            false,
            messages
        );

        store.dispatch(dimensionErrorsSet(dimensionMessages));
    },
});

export const pathsSetEffect = () => ({
    actionCreator: pathsSet,
    effect: (
        action: PayloadAction<Path[], string, {updateJoin: boolean}>,
        listenerApi: ListenerEffectAPI<AppState, AppDispatch>
    ) => {
        if (action && action.meta && action.meta.updateJoin) {
            const state = listenerApi.getState();
            const shape = state.btm.type;
            const joins = state.btm.joins;
            const edgeProfile = state.btm.edgeProfile;
            const paths = state.btm.paths;

            if (shape.type != Shape.SQR) {
                const newJoins = joinRestrictions(
                    cloneDeep(joins),
                    paths,
                    edgeProfile
                );

                store.dispatch(joinsSet(newJoins));
            }
        }
    },
});
