import {AppApi} from 'store/customer/api';
import {EdgeFinish} from 'components/customer/EdgeFinishes/Entity/EdgeFinish';

interface EdgeFinishRequest {
    materialId: number;
    edgeId: number;
    doorId?: number;
}

const mapRestrictions = (edgeFinish: EdgeFinish): EdgeFinish => {
    return {
        ...edgeFinish,
        restrict_adjacent: !!edgeFinish['restrict-adjacent'],
        restrictions: Array.isArray(edgeFinish['restrictions'])
            ? {}
            : {
                  adjacent: edgeFinish.restrictions.adjacent,
              },
    };
};

const EdgeFinishApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getEdgeFinishes: builder.query<EdgeFinish[], EdgeFinishRequest>({
            query: ({materialId, edgeId, doorId}) => {
                if (doorId) {
                    return `edgefinishes/material/${materialId}/edge/${edgeId}/door/${doorId}`;
                }

                return `edgefinishes/material/${materialId}/edge/${edgeId}`;
            },
            transformResponse: (response: {edge_finishes: EdgeFinish[]}) =>
                response.edge_finishes.map(mapRestrictions),
            providesTags: (result, error, args) => [
                {
                    type: 'EdgeFinishes',
                    ...args,
                },
            ],
        }),
    }),
});

export const {useLazyGetEdgeFinishesQuery} = EdgeFinishApi;
